import React from 'react';
import PropTypes from 'prop-types';

import DesktopMenu from './components/DesktopMenu';
import MobileMenu from './components/MobileMenu';

import IconTarget from '../../../resources/icons/icon-target.svg';
import IconPeople from '../../../resources/icons/icon-people.svg';
import IconPage from '../../../resources/icons/icon-page.svg';
import IconPaperclip from '../../../resources/icons/icon-paperclip.svg';
import IconHorizon from '../../../resources/icons/icon-horizon.svg';

export function getIcon(name) {
  switch(name) {
    default:
    case 'icon-target.svg':
      return IconTarget;
    case 'icon-people.svg':
      return IconPeople;
    case 'icon-page.svg':
      return IconPage;
    case 'icon-paperclip.svg':
      return IconPaperclip;
    case 'icon-horizon.svg':
      return IconHorizon;
  }
}

class AppMenuWrapper extends React.Component {
  render() {
    if (this.props.isMobile) {
      return <MobileMenu {...this.props} />;
    }
    return <DesktopMenu {...this.props} />;
  }
}

AppMenuWrapper.defaultProps = {
  page: 0,
};

AppMenuWrapper.propTypes = {
  // props
  isMobile: PropTypes.bool,
  fullscreen: PropTypes.bool,
  page: PropTypes.number,
  // funcs
  onNavigate: PropTypes.func.isRequired,
  onToggleFullscreen: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
};

export default AppMenuWrapper;
