import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import parse from '../../../../utils/parse';

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import LogoUrl from '../../../../resources/logo.svg';
import XIconUrl from '../../../../resources/icons/icon-x.svg';
import YoutubeIconUrl from '../../../../resources/icons/icon-youtube.svg';
import LinkedinIconUrl from '../../../../resources/icons/icon-linkedin.svg';
import DownloadIconUrl from '../../../../resources/icons/icon-download-white.svg';

import {
  WEBSITE_URL,
  LINKEDIN_URL,
  MENU_HIERARCHY,
  MENU_DESKTOP_WIDTH,
  YOUTUBE_URL,
  X_URL,
} from '../../../../config';

import ShareButton from './ShareButton';
import styles from '../styles';
import { IconButton } from '@mui/material';
import { getIcon } from '../index';

class DesktopAppMenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentHighlightedItem: null,
      isHover: false,
    }
  }
  /**
   * HANDLE
   */
  handleMouseEnter(evt, params = null) {
    const setState = {
      isHover: false,
    };
    if (params) {
      setState.currentHighlightedItem = params;
      if (params.list && params.list.length > 0) {
        setState.isHover = true;
      }
    }
    this.setState(setState);
  }

  handleMouseLeave(evt) {
    const { relatedTarget, toElement } = evt.nativeEvent;
    if (relatedTarget) {
      if (relatedTarget.classList.contains('bookmarkButton')) {
        return;
      }
      if (relatedTarget.closest('.subDrawer')) {
        return;
      }
    }
    if (toElement) {
      if (toElement.classList.contains('bookmarkButton')) {
        return;
      }
      if (toElement.closest('.subDrawer')) {
        return;
      }
    }
    this.setState({ isHover: false });
  }

  /**
   * RENDER
   */
  renderMainDrawer() {
    const { classes, page, fullscreen, fileURL, onNavigate } = this.props;
    return (
      <Drawer
        className={classes.mainDrawer}
        variant="persistent"
        anchor="left"
        open={!fullscreen}
      >
        <Box className={classes.mainDrawerHeader}>
          <a href={WEBSITE_URL} target="_blank" rel="noopener noreferrer">
            <img src={LogoUrl} alt="Proparco" style={{height: '50px'}} />
          </a>
        </Box>
        <List sx={{
          width: 'calc(100% + 17px)',
          height: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
          paddingRight: '17px',
          boxSizing: 'content-box',
        }}>
          {MENU_HIERARCHY.map((item, idx) => {
            const isFocus = page >= item.pages[0] && page <= item.pages[1];
            return (
              <ListItem key={item.label} className={`${classes.listItem} ${item.color} ${isFocus ? 'focus' : ''}`} disablePadding>
                <ListItemButton
                  sx={{ p: 0, my: 1 }}
                  className="bookmarkButton"
                  onClick={() => onNavigate(item.pages[0])}
                  onMouseEnter={evt => this.handleMouseEnter(evt, item)}
                  onMouseLeave={(evt, b) => this.handleMouseLeave(evt, item, b)}
                >
                  {item.icon && (
                  <ListItemIcon className={classes.menuIconWrapper}>
                    <img className={classes.menuIcon} src={getIcon(item.icon)} alt={item.label} />
                  </ListItemIcon>
                  )}
                  <ListItemText
                    className={`${classes.drawerLabel} ${item.color} category`}
                    primary={<Typography variant="h3" fontSize='16px'>{parse(item.label)}</Typography>}
                    secondary={<span className={`listItemFocus ${item.color}`}></span>}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>

        <Stack className={classes.socialList} justifyContent='center' style={{ backgroundColor: '#2f3772', flexGrow: 1, maxHeight: '300px' }} sx={{
          pt: 1,
          pb: 2,
        }}>
          <Stack>
            <ShareButton classes={classes} />
            <ListItem key="download" className={classes.listItem} disablePadding>
              <ListItemButton
                onClick={() => {
                  // Event for Downloads stat with analytics
                  // window.gtag('event', 'download_pdf', {
                  //   'event_category': 'Proparco - Rapport d\'impact 2023',
                  //   'event_label': 'download_pdf_click'
                  // });
                  const link = document.createElement("a");
                  link.href = fileURL;
                  link.download = 'Les-actes_cycle-2024_Palladio.pdf';
                  link.click();
                }}
              >
                <ListItemIcon>
                  <img className={classes.menuIcon} src={DownloadIconUrl} alt="Télécharger ce pdf" />
                </ListItemIcon>
                <ListItemText className={`${classes.drawerLabel} ${classes.whiteLabel}`} primary={<Typography variant="h4" color="primary.contrastText">Télécharger ce pdf</Typography>} />
              </ListItemButton>
            </ListItem>
          </Stack>
          <ListItem key="social" className={classes.listItem} disableGutters disablePadding>
            <Stack className={classes.socialList} direction="row" justifyContent="center" spacing={0.25}>
              <IconButton key="linkedin" size="small" onClick={() => window.open(LINKEDIN_URL, '_blank')}>
                <img src={LinkedinIconUrl} alt="Linkedin" />
              </IconButton>
              <IconButton key="x" size="small" onClick={() => window.open(X_URL, '_blank')}>
                <img src={XIconUrl} alt="X" />
              </IconButton>
              <IconButton key="youtube" size="small" onClick={() => window.open(YOUTUBE_URL, '_blank')}>
                <img src={YoutubeIconUrl} alt="Youtube" />
              </IconButton>
            </Stack>
          </ListItem>
        </Stack>
      </Drawer>
    );
  }

  renderSubDrawer() {
    const { classes, page, onNavigate } = this.props;
    const { isHover, currentHighlightedItem } = this.state;

    const color = (currentHighlightedItem && currentHighlightedItem.color) || 'darkblue';
    return (
      <Drawer
        className={`${classes.subDrawerContainer} ${classes.subDrawer} ${color} subDrawer`}
        variant="persistent"
        anchor="left"
        open={isHover}
      >
        {currentHighlightedItem && (
          <Box
            className={classes.subDrawerPaper}
            onMouseLeave={evt => this.handleMouseLeave(evt)}
          >
            {currentHighlightedItem.list && (
              <List className={classes.subList} sx={{pl: 2}}>
                {currentHighlightedItem.list.map(bookmark => {
                  const isFocus = page >= (bookmark.pages?.[0] || 0) && page <= (bookmark.pages?.[1] || 0);

                  return (
                    <ListItem
                      key={bookmark.label}
                      className={`${classes.subListItem} ${currentHighlightedItem.color} ${isFocus ? 'focus' : ''}`}
                      disablePadding
                    >
                      {/* Vérifie si l'URL existe */}
                      {bookmark.url ? (
                        <ListItemButton href={bookmark.url} target='_blank'>
                          <ListItemText
                            className={`${classes.subListItemLabel} ${currentHighlightedItem.color}`}
                            primary={<Typography variant="h4">{parse(bookmark.label)}</Typography>}
                            secondary={<span className={`listItemFocus ${currentHighlightedItem.color}`}></span>}
                          />
                        </ListItemButton>
                      ) : (
                        // Si pages est un tableau non vide, afficher ListItemButton avec onClick
                        Array.isArray(bookmark.pages) && bookmark.pages.length > 0 ? (
                          <ListItemButton onClick={() => onNavigate(bookmark.pages[0])}>
                            <ListItemText
                              className={`${classes.subListItemLabel} ${currentHighlightedItem.color}`}
                              primary={<Typography variant="h4">{parse(bookmark.label)}</Typography>}
                              secondary={<span className={`listItemFocus ${currentHighlightedItem.color}`}></span>}
                            />
                          </ListItemButton>
                        ) : (
                          // Si pages est un tableau vide, afficher uniquement ListItemText
                          <ListItemText
                          sx={{pl: 2}}
                            className={`${classes.subListItemLabel} ${currentHighlightedItem.color}`}
                            primary={<Typography variant="h4">{parse(bookmark.label)}</Typography>}
                            secondary={<span className={`listItemFocus ${currentHighlightedItem.color}`}></span>}
                          />
                        )
                      )}
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Box>
        )}
      </Drawer>
    );
  }

  render() {
    const { classes, fullscreen } = this.props;
    return (
      <Stack
        className={classes.desktopMenuContainer}
        sx={{ width: fullscreen ? 0 : MENU_DESKTOP_WIDTH }}
        direction="row"
      >
        {this.renderMainDrawer()}
        {this.renderSubDrawer()}
      </Stack>
    );
  }
}

DesktopAppMenu.defaultProps = {
  page: 0,
};

DesktopAppMenu.propTypes = {
  // props
  fullscreen: PropTypes.bool,
  page: PropTypes.number,
  fileURL: PropTypes.string,
  // funcs
  onNavigate: PropTypes.func.isRequired,
};

export default withStyles(styles)(DesktopAppMenu);
