import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import LogoUrl from '../../../../resources/logo.svg';
import FullscreenIconUrl from '../../../../resources/icons/icon-fullscreen.svg';
import ToolsIconUrl from '../../../../resources/icons/icon-tools.svg';
import ZoomIconUrl from '../../../../resources/icons/icon-zoom.svg';
import ShareButton from './ShareButton';
import XIconUrl from '../../../../resources/icons/icon-x.svg';
import YoutubeIconUrl from '../../../../resources/icons/icon-youtube.svg';
import LinkedinIconUrl from '../../../../resources/icons/icon-linkedin.svg';
import DownloadIconUrl from '../../../../resources/icons/icon-download-white.svg';
import {
  WEBSITE_URL,
  LINKEDIN_URL,
  MENU_MOBILE_FOOTER_HEIGHT,
  X_URL,
  YOUTUBE_URL,
  MENU_HIERARCHY_MOBILE
} from '../../../../config';

import styles from '../styles';
import parse from '../../../../utils/parse';
import { getIcon } from '..';

class MobileAppMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentHighlightedItem: null,
      swipeMenuOpen: false,
    };
  }
  /**
   * HANDLE
   */
  handleNavigate(page) {
    const { onNavigate } = this.props;
    this.setState({ swipeMenuOpen: false });
    onNavigate(page);
  }
  /**
   * RENDER
   */
  renderHeader() {
    const { classes, onToggleFullscreen, onZoom } = this.props;
    return (
      <AppBar
        className={classes.mobileMenuHeader}
        elevation={0}
        color="inherit"
        position="fixed"
      >
        <Toolbar disableGutters>
          <Stack sx={{ width: '100%', height: '100%' }} direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <a href={WEBSITE_URL} target="_blank" rel="noopener noreferrer">
              <img src={LogoUrl} alt="Proparco" className='headerlogo' />
            </a>
            <Box sx={{ flex: 1 }}></Box>
            <Stack alignItems="center">
              <Stack direction="row">
                <IconButton onClick={() => onToggleFullscreen(true)}>
                  <img className={classes.menuIcon} src={FullscreenIconUrl} alt="Plein écran" />
                </IconButton>
                <IconButton onClick={() => onZoom()}>
                  <img className={classes.menuIcon} src={ZoomIconUrl} alt="Zoom" />
                </IconButton>
                <IconButton onClick={() => this.setState({ currentHighlightedItem: 'MENU', swipeMenuOpen: true })}>
                  <img className={classes.menuIcon} src={ToolsIconUrl} alt="Menu" />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
    );
  }

  renderFooter() {
    const { classes, page, fileURL } = this.props;
    const { currentHighlightedItem, swipeMenuOpen } = this.state;

    const color = (currentHighlightedItem && currentHighlightedItem.color) || 'blue';

    return (
      <div className={`${classes.mobileMenuFooter} ${swipeMenuOpen ? 'swipeMenuOpen' : ''} ${color}`}>
        <SwipeableDrawer
          anchor="bottom"
          open={swipeMenuOpen}
          onClose={() => this.setState({ swipeMenuOpen: false })}
          onOpen={() => { }}
          swipeAreaWidth={MENU_MOBILE_FOOTER_HEIGHT}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            className: `${classes.mobileMenuSwipe} ${classes.subDrawerMobile} ${color}`,
            elevation: 0,
          }}
        >
          <Paper
            className={classes.mobileMenuSwipePaper}
            elevation={0}
            sx={{ backgroundColor: currentHighlightedItem && currentHighlightedItem !== 'MENU' ? '' : `primary !important` }}
          >
            <Box className={classes.mobileMenuPuller} />
            {currentHighlightedItem && currentHighlightedItem !== 'MENU' ? (
              <>
                <ListItem className={`${classes.subListItemMobile} ${currentHighlightedItem.color} ${page >= currentHighlightedItem.pages[0] && page <= currentHighlightedItem.pages[1] ? 'focus' : ''}`} disablePadding>
                  <ListItemButton onClick={() => this.handleNavigate(currentHighlightedItem.pages[0])}>
                    <ListItemText
                      className={`${classes.subListItemLabel} ${currentHighlightedItem.color}`}
                      primary={<Typography variant="h3">{parse(currentHighlightedItem.label)}</Typography>}
                      secondary={<span className={`listItemFocus ${currentHighlightedItem.color}`}></span>}
                    />
                  </ListItemButton>
                </ListItem>
              </>
            ) : (
              <>
                <Stack sx={{ mb: 2, mt: 2 }} direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <ShareButton classes={classes} isMobile />
                  <ListItem key="download" className={classes.listItem} disablePadding sx={{ justifyContent: 'center' }}>
                    <ListItemButton
                      className={classes.shareAndDownloadMobileButton}
                      sx={{ flexGrow: 0 }}
                      onClick={() => {
                        const link = document.createElement("a");
                        link.href = fileURL;
                        link.download = 'Les-actes_cycle-2024_Palladio.pdf';
                        link.click();
                      }}
                    >
                      <ListItemIcon>
                        <img className={classes.menuIcon} src={DownloadIconUrl} alt="Télécharger ce pdf" />
                      </ListItemIcon>
                        <ListItemText className={classes.drawerLabel} primary={<Typography variant="h4">Télécharger ce pdf</Typography>} />
                    </ListItemButton>
                  </ListItem>
                    <ListItem key="social" className={classes.listItem} disableGutters disablePadding>
                      <Stack className={classes.socialList} direction="row" justifyContent="center" spacing={0.25}>
                        <IconButton key="linkedin" size="small" onClick={() => window.open(LINKEDIN_URL, '_blank')}>
                          <img src={LinkedinIconUrl} alt="Linkedin" />
                        </IconButton>
                        <IconButton key="x" size="small" onClick={() => window.open(X_URL, '_blank')}>
                          <img src={XIconUrl} alt="X" />
                        </IconButton>
                        <IconButton key="youtube" size="small" onClick={() => window.open(YOUTUBE_URL, '_blank')}>
                          <img src={YoutubeIconUrl} alt="Youtube" />
                        </IconButton>
                      </Stack>
                    </ListItem>
                </Stack>
              </>
            )}
          </Paper>
        </SwipeableDrawer>
        <Stack className={classes.mobileMenuFooterContent} direction="row" alignItems="flex-end" justifyContent="space-evenly" spacing={2}>
          {MENU_HIERARCHY_MOBILE.map(item => {
            const isFocus = page >= item.pages[0] && page <= item.pages[1];
            const isMenuOpen = swipeMenuOpen && item === currentHighlightedItem;
            return (
              <div key={item.label} className={`${classes.mobileMenuButtonWrapper} ${isFocus ? 'focus' : ''} ${item.color}`}>
                <Button
                  className={`${classes.mobileMenuButton} ${isMenuOpen ? 'menuOpen' : ''} ${isFocus ? 'focus' : ''} ${item.color}`}
                  size="small"
                  onClick={() => this.setState({ currentHighlightedItem: item, swipeMenuOpen: true })}
                >
                  <img className={classes.menuIcon} src={getIcon(item.icon)} alt={item.label} />
                </Button>
              </div>
            );
          })}
        </Stack>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderHeader()}
        {this.renderFooter()}
      </>
    );
  }
}

MobileAppMenu.propTypes = {
  // props
  categoryOpen: PropTypes.string,
  // funcs
  onNavigate: PropTypes.func.isRequired,
  onToggleFullscreen: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
}

export default withStyles(styles)(MobileAppMenu);
