export const PDF_NAME = 'ABM-Plaquette-internationnale-FR-PC.pdf';
export const PDF_NAME_MOBILE = 'ABM-Plaquette-internationnale-FR-Mobile.pdf';
export const PDF_NAME_HR = 'ABM-Plaquette-internationnale-FR-PC.pdf';

export const SKIP_ANIMATION_STORAGE_KEY = 'SKIP_ANIMATION';

export const MENU_HIERARCHY = [
  {
    label: "Missions de l'agence",
    focusLabel: "Missions de l'agence",
    icon: "icon-target.svg",
    color: 'blue',
    pages: [2, 3],
    list: [],
  },
  {
    label: "Relations internationales",
    focusLabel: "Relations internationales",
    icon: "icon-people.svg",
    color: 'orange',
    pages: [4, 5],
    list: [],
  },
  {
    label: "Affaires européennes",
    focusLabel: "Affaires européennes",
    icon: "icon-page.svg",
    color: 'yellow',
    pages: [6, 7],
    list: [],
  },
  {
    label: "Horizons futurs",
    focusLabel: "Horizons futurs",
    icon: "icon-horizon.svg",
    color: 'green',
    pages: [8, 9],
    list: [],
  },
];

export const MENU_HIERARCHY_MOBILE = [
  {
    label: "Missions de l'agence",
    focusLabel: "Missions de l'agence",
    icon: "icon-target.svg",
    color: 'blue',
    pages: [2, 3],
    list: [],
  },
  {
    label: "Relations internationales",
    focusLabel: "Relations internationales",
    icon: "icon-people.svg",
    color: 'orange',
    pages: [4, 5],
    list: [],
  },
  {
    label: "Affaires européennes",
    focusLabel: "Affaires européennes",
    icon: "icon-page.svg",
    color: 'yellow',
    pages: [6, 7],
    list: [],
  },
  {
    label: "Horizons futurs",
    focusLabel: "Horizons futurs",
    icon: "icon-horizon.svg",
    color: 'green',
    pages: [8, 9],
    list: [],
  },
];

export const MENU_DESKTOP_WIDTH = 350;
export const MENU_MOBILE_HEADER_HEIGHT = 85;
export const MENU_MOBILE_FOOTER_HEIGHT = 75;
export const NAVIGATION_HEIGHT = 52;
export const MOBILE_NAVIGATION_HEIGHT = 40;
export const PDF_PAGE_RATIO = 210 / 297;
// export const PDF_PAGE_RATIO = 420 / 297;
export const MODE_SINGLE_PAGE = false;
export const TITLE_LOADER = 'Agence de la biomédecine';
export const PRESENTATION_TEXT = "";
export const DOWNLOAD_TEXT = 'Télécharger ce pdf';
export const SHARE_TEXT = 'Partager ce pdf';
export const SHARE_TEXT_FACEBOOK = 'Partager sur Facebook';
export const SHARE_TEXT_X = 'Partager sur X';
export const SHARE_TEXT_LINKEDIN = 'Partager sur LinkedIn';
export const SHARE_TEXT_COPYPASTE = 'Copier le lien dans le presse-papier';
export const SHARE_TEXT_COPYPASTE_SUCCESS = 'Lien copié dans le presse papier';

export const FOLLOW_TEXT = 'NOUS SUIVRE';

const SHARE_URL = encodeURIComponent(window.location.href);
const SHARE_DESCRIPTION = encodeURIComponent(" Nos activités à l'international 2022 - 2023");
const SHARE_TITLE = encodeURI('Agence de la biomédecine');
export const WEBSITE_URL = 'https://www.agence-biomedecine.fr/';
export const FACEBOOK_SHARE_PARAMS = `http://www.facebook.com/share.php?u=${SHARE_URL}`;
export const X_SHARE_PARAMS = `http://x.com/intent/tweet?text=${SHARE_DESCRIPTION}&url=${SHARE_URL}`;
export const LINKEDIN_SHARE_PARAMS = ` https://www.linkedin.com/shareArticle?mini=true&url=${SHARE_URL}&title=${SHARE_TITLE}&summary=${SHARE_DESCRIPTION}`;
export const LINKEDIN_URL = 'https://www.linkedin.com/company/agence-de-la-biomedecine/?originalSubdomain=fr';
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UCxbqRMcfj6xvHG3uPqTw2GQ';
export const X_URL = 'https://x.com/ag_biomedecine';

const CONFIG = {
  PDF_NAME,
  SKIP_ANIMATION_STORAGE_KEY,
  MENU_DESKTOP_WIDTH,
  MENU_MOBILE_HEADER_HEIGHT,
  MENU_MOBILE_FOOTER_HEIGHT,
  PDF_PAGE_RATIO,
  NAVIGATION_HEIGHT,
  MENU_HIERARCHY,
  MOBILE_NAVIGATION_HEIGHT,
  FACEBOOK_SHARE_PARAMS,
  X_SHARE_PARAMS,
  WEBSITE_URL,
};

export default CONFIG;
