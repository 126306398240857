import Theme from '../../../theme';
import { alpha } from '@mui/material/styles';
import { MENU_DESKTOP_WIDTH, MENU_MOBILE_HEADER_HEIGHT, MENU_MOBILE_FOOTER_HEIGHT } from '../../../config';

const appMenu = ({
  desktopMenuContainer: {
    height: '100%',
  },
  mainDrawer: {
    width: MENU_DESKTOP_WIDTH,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      backgroundColor: Theme.palette.white.main,
      width: MENU_DESKTOP_WIDTH,
      boxSizing: 'border-box',
      border: 'none',
      overflow: 'hidden',
      justifyContent: 'space-between',
      zIndex: 9,
    },
  },
  mainDrawerHeader: {
    padding: `${Theme.spacing(4)} ${Theme.spacing(4)}`,
    textAlign: 'center',
    '& img': {
      width: '90%',
      objectFit: 'contain',
    },
  },
  mainDrawerHeaderLink: {
    textDecoration: 'none',
    color: Theme.palette.primary.main,
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: Theme.palette.secondary.main,
    }
  },
  mainDrawerParagraph: {
    marginTop: Theme.spacing(8),
    padding: `${Theme.spacing(8)} ${Theme.spacing(0)} ${Theme.spacing(4)} ${Theme.spacing(6)}`,
    textAlign: 'left',
    color: Theme.palette.primary.main,
    fontSize: '16px !important',
    lineHeight: '1.3 !important',
  },
  buttonDrawerParagraph: {
    marginTop: Theme.spacing(8),
    padding: `${Theme.spacing(0)} ${Theme.spacing(0)} ${Theme.spacing(0)} ${Theme.spacing(6)}`,
    textAlign: 'left',
    color: Theme.palette.primary.main,
    fontSize: '16px !important',
    lineHeight: '1.3 !important',
  },
  '&.focus': {
    backgroundColor: 'red',
    '& h2': {
      position: 'relative',
      '&::after': {
        width: '50vw',
      },
    },
    '&.darkblue': {
      backgroundColor: Theme.palette.primary.main,
      '&::after': {
        backgroundColor: Theme.palette.secondary.main,
      },
    },
  },
  listItem: {
    transition: 'background-color 500ms',
    color: Theme.palette.primary.main,
    '&:hover': {
      // color: Theme.palette.secondary.main,
    },
    '& h3::after': {
      content: '""',
      display: 'block',
      height: '1px',
      width: 0,
      position: 'absolute',
      left: 0, 
      bottom: '2px',
      transform: 'translateX(-50%)',
      transition: 'width 1000ms ease-in-out',
    },
    '& .MuiListItemButton-root': {
      paddingLeft: Theme.spacing(2),
      paddingRight: Theme.spacing(2),
      textTransform: 'initial',
      '& .MuiListItemIcon-root': {
        alignSelf: 'flex-start',
        marginTop: 5,
      },
    },
  },
  drawerLabel: {
    color: 'inherit',
    '&.category': {
      // textTransform: 'uppercase',
    },
    '& .MuiTypography-root': {
      fontWeight: 400,
    },
  },
  listItemEndButton: {
    width: 200,
    borderBottomLeftRadius: '30px !important',
    borderBottomRightRadius: '30px !important',
    borderTopLeftRadius: '30px !important',
    borderTopRightRadius: '30px !important',
    border: 'none !important',
    backgroundColor: `${Theme.palette.primary.main} !important`,
    color: `${Theme.palette.white.contrastText} !important`,
    '&:hover': {
      backgroundColor: `${Theme.palette.primary.light} !important`,
    },
    '& .MuiTypography-h2': {
      fontSize: '27 !important',
      lineHeight: '37px !important',
      color: 'inherit !important',
    },
  },
  subDrawerContainer: {
    width: `calc(${MENU_DESKTOP_WIDTH}px + 20px)`,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: `calc(${MENU_DESKTOP_WIDTH}px + 20px)`,
      left: `calc(${MENU_DESKTOP_WIDTH}px - 20px)`,
      paddingLeft: 20,
      boxSizing: 'border-box',
      border: 'none',
      overflow: 'hidden',
      zIndex: 8,
      transition: 'transform 225ms, background-color 120ms !important',
    },
  },
  subDrawer: {
    '& .MuiPaper-root': {
      backgroundColor: alpha(Theme.palette.secondary.main, 1),
    },
  },
  subDrawerMobile: {
    '& .MuiPaper-root': {
      backgroundColor: alpha(Theme.palette.primary.main, 0.95),
    },
  },
  subDrawerPaper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    "& ::-webkit-scrollbar": {
      width: 8,
      height: 10,
    },
    "& ::-webkit-scrollbar-thumb": {
      background: Theme.palette.primary.contrastText,
      borderRadius: 5,
      height: '10px',
      border: '2px solid transparent',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: `${Theme.palette.primary.light} transparent`,
    '& .MuiTypography-h2': {
      padding: Theme.spacing(4),
      paddingTop: Theme.spacing(6),
    },
    '& > .MuiList-root': {
      overflowY: 'auto',
      '& .MuiListItem-root:last-child': {
        paddingBottom: Theme.spacing(6),
      },
    },
  },
  subList: {
    overflow: 'auto',
  },
  subListItem: {
    '&::after': {
      content: '""',
      width: 10,
      height: '100%',
      position: 'absolute',
      right: 0,
      transition: 'background-color 250ms',
      pointerEvents: 'none',
    },
    color: Theme.palette.primary.main,
    '& > .MuiListItemButton-root': {
      // paddingLeft: Theme.spacing(6),
    }
  },
  subListItemMobile: {
    '&::after': {
      content: '""',
      width: 10,
      height: '100%',
      position: 'absolute',
      right: 0,
      transition: 'background-color 250ms',
      pointerEvents: 'none',
    },
    color: Theme.palette.white.main,
    '& > .MuiListItemButton-root': {
      // paddingLeft: Theme.spacing(6),
    }
  },
  socialList: {
    width: '100%', 
    '& img': {
      width: 25,
      height: 25
    }
  },
  socialListMobile: {
    width: '80%',
    margin: 'auto',
    marginTop: Theme.spacing(2),
    marginBottom: Theme.spacing(1),
    '& img': {
      width: 35,
      height: 35
    }
  },
  menuIconWrapper: {
    minWidth: '30px !important',
  },
  menuIcon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
  },
  flagIcon: {
    width: 60,
    objectFit: 'contain',
  },
  sharePopper: {
    zIndex: 9,
    '& .MuiTypography-root': {
      color: Theme.palette.primary.ligth,
    },
  },
  logoLoader: {
    width: 100,
    objectFit: 'contain',
  },
  mobileMenuHeader: {
    height: MENU_MOBILE_HEADER_HEIGHT,
    padding: Theme.spacing(2, 4, 2),
    backgroundColor: Theme.palette.white.main,
    transition: 'top 250ms !important',

    '& img': {
      maxWidth: 200,
      height: 32,
      objectFit: 'contain',
    },

    '.fullscreen &': {
      top: `-${MENU_MOBILE_HEADER_HEIGHT}px`,
    },
  },
  mobileMenuFooter: {
    // display: 'none',
    width: '100%',
    height: MENU_MOBILE_FOOTER_HEIGHT,
    padding: Theme.spacing(1, 2, 0),
    boxSizing: 'border-box',
    position: 'fixed',
    bottom: 0,
    color: Theme.palette.white.main,
    backgroundColor: Theme.palette.white.main,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    transition: 'bottom 250ms !important',
    overflow: 'hidden',
    zIndex: 1300,
    
    '&.pageHasLink': {
      transition: 'bottom 250ms !important 250ms',
      bottom: 0,
    },

    '.fullscreen &': {
      bottom: `-${MENU_MOBILE_FOOTER_HEIGHT}px`,
    },
  },
  mobileMenuFooterContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileMenuButtonWrapper: {
    paddingBottom: Theme.spacing(2),
    position: 'relative',
    marginLeft: '0 !important',
    '&.focus': {
      '&.yellow': {
        '& h2' : {
          color: "#FBB017",
        },
        '&::after': {
          backgroundColor: Theme.palette.secondary.main,
        },
      },
      '&.orange': {
        '& h2' : {
          color: "#F36E21",
        },
        '&::after': {
          backgroundColor: Theme.palette.secondary.main,
        },
      },
      '&.blue': {
        '& h2' : {
          color: "#2f3772",
        },
        '&::after': {
          backgroundColor: Theme.palette.secondary.main,
        },
      },
      '&.green': {
        '& h2' : {
          color: "#18AA97",
        },
        '&::after': {
          backgroundColor: Theme.palette.secondary.main,
        },
      },
    },
    
    '& h2': { color: Theme.palette.secondary.main },

    '&::after': {
      content: '""',
      width: '100%',
      height: 10,
      position: 'absolute',
      left: 0,
      bottom: 0,
      transition: 'background-color 250ms',
      pointerEvents: 'none',
    },
  },
  mobileMenuFooterLink: {
    backgroundColor: Theme.palette.primary.main,
    color: Theme.palette.white.main,
    borderRadius: '50px',
    textDecoration: 'none',
    padding: `${Theme.spacing(1)} ${Theme.spacing(2)}`,
  },
  mobileMenuButton: {
    position: 'relative',
    bottom: 0,
    transition: 'background-color 250ms, bottom 250ms !important',
    width: '40px !important',
    textAlign: 'center',
    margin:'auto',
    minWidth: 'initial !important',
    
    '&.menuOpen': {
      bottom: Theme.spacing(1),
    },
  }, 
  mobileMenuSwipe: {
    overflow: 'hidden',
    
    '& .MuiTypography-h2': {
      paddingLeft: Theme.spacing(4),
      paddingRight: Theme.spacing(4),
      fontSize: 26,
      lineHeight: '32px',
    },
    '& .MuiTypography-h4': {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  mobileMenuSwipePaper: {
    paddingTop: Theme.spacing(4),
    paddingBottom: `calc(${MENU_MOBILE_FOOTER_HEIGHT}px + 5px)`,
    boxSizing: 'border-box',
  },
  mobileMenuPuller: {
    width: 30,
    height: 6,
    backgroundColor: Theme.palette.white.dark,
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
  },
  shareAndDownloadMobileButton: {
    paddingLeft: `12px !important`,
    '& .MuiTypography-root': {
      color: Theme.palette.white.main,
    },
    '& .MuiListItemIcon-root': {
      minWidth: '35px !important',
    },
  },
});

export default appMenu;